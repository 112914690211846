<template>
    <div>
        <div>
            <Breadcrumb content="编辑文章"/>
        </div>
        <div class="m-bg m-pd">
            <el-form v-model="form" label-width="100px" >
                <div>
                    <div class="m-fl" style="width: 45%">
                        <el-form-item label="类型">
                            <el-select class="m-full" v-model="form.type">
                                <el-option
                                        v-for="(v,k) in $webConfig.articleTypes"
                                        :key="k"
                                        :label="v.name"
                                        :value="v.type"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="标题">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>

                        <el-form-item label="简介">
                            <el-input type="textarea" v-model="form.schema" :rows="6"></el-input>
                        </el-form-item>
                    </div>
                    <div class="m-fr" style="width: 52%">
                        <el-form-item label="封面图">
                            <div style="width:100%;">
                                <AvatarUpload
                                        flag="banner"
                                        @uploadSuccess="handleBanner"
                                        :outImgUrl="form.banner_img"
                                        v-if="form.id"
                                ></AvatarUpload>
                            </div>
                            <div class="m-tips">
                                封面banner:分辨率要求 480 x 312比例
                            </div>
                        </el-form-item>

                        <el-form-item label="每日一卡" v-if="form.type == 88">
                            <div style="width:100%;">
                                <AvatarUpload
                                        flag="card"
                                        @uploadSuccess="handleBanner"
                                        :outImgUrl="form.card_img"
                                ></AvatarUpload>
                            </div>
                            <div class="m-tips">
                                每日一卡:正常卡图比例
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="m-clear-both"></div>
                <el-form-item label="新闻内容">
                    <Editor @getEditorVal="getEditorVal" :editorVal="form.content" v-if="form.id>0"></Editor>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="submit">立即保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import Editor from '@/components/admin/editor'
    import AvatarUpload from "@/components/admin/avatar-upload"
    export default {
        name: "add",
        components:{Breadcrumb,Editor,AvatarUpload},
        data() {
            return {
                form:{
                    type:"",
                    title:"",
                    schema:"",
                    banner_img:"",
                    card_img:"",
                    content:"",
                    id:"",
                }
            }
        },
        methods: {
            getEditorVal(v) {
                this.form.content = v;
            },
            handleBanner(obj) {
                switch (obj.flag) {
                    case "banner":
                        this.form.banner_img = obj.imgUrl;
                        break;
                    case "card":
                        this.form.card_img = obj.imgUrl;
                        break;
                }
            },
            submit(){
                this.$axios.post("site/article/edit",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");
                            setTimeout(()=>{
                                this.$router.push("article")
                            },1000)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            getInfo(){
                let id = this.$route.query.id;
                let type = this.$route.query.type;
                this.$axios.post("site/article/getInfo",{id,type})
                    .then((res)=>{
                        if (res.data.code == 1000){
                            let info = res.data.data.info;

                            this.form.banner_img = info.banner_img;
                            this.form.card_img   = info.card_img;
                            this.form.title      = info.title;
                            this.form.content    = info.content;
                            this.form.schema     = info.schema;
                            this.form.type       = info.type;
                            this.form.id         = info.id;
                        }else{
                            // this.$message.error(res.data.msg)
                        }
                    })
            }
        },
        created() {
            this.getInfo();
        }
    }
</script>

<style scoped>

</style>
